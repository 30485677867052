<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <CustomDeliverFeeDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="updatePackageDeliverFee"
    ></CustomDeliverFeeDialog>
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="images"
      @hide="closeLightBox"
    ></vue-easy-lightbox>
    <v-row>
      <v-col>
        <v-btn
          icon
          class="hidden-xs-only"
          @click="
            $router.push({
              name: 'CustomDeliverFeeList',
              params: {}
            })
          "
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-card class="px-4">
          <v-row>
            <v-col>{{ $t('container.total_amount') + ":" + fixedFloat(total_amount,2,0) }}</v-col>
            <v-col>{{ $t('container.total_net_weight') + ":" + fixedFloat(total_net_weight,2,0) }}</v-col>
            <v-col>{{ $t('container.summary') + ":" + fixedFloat(total_custom_deliver_fee,2,0) }}</v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="refreshUnitPrice"
        >
          <v-icon>mdi-refresh</v-icon>
          {{ $t("container-box.refresh-unit-price") }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="exportApplyList"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("export")+$t("apply_list") }}
        </v-btn>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.custom-deliver-fee') + '--' + container_name"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        fixed-header
        :height="windowHeight*0.8"
        :item-class="itemRowBackground"
        class="custom-deliver-fee-detail-table"
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100, 200]"
          />
        </template>
        <template v-slot:item.end_box="{ item }">
          {{ get_end_box(item) }}
        </template>
        <template v-slot:item.customs_type__order="{ item }">
          <template v-for="data in item.relation">
            <v-edit-dialog
              @save="updateRelation(data.id,'customs_type',customs_type)"
              @open="editOpen(data.customs_type)"
              @close="editClose()"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ get_customs_type_name(data) }}<v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <CommonAutocomplete
                  v-model="customs_type"
                  url="/commodity/customs-type/"
                  :item_text="nameField"
                  item_value="id"
                  :query_field="nameFieldFilter"
                  :cache="[customs_type_cache]"
                  :extra_condition="{
                    enable: true
                  }"
                ></CommonAutocomplete>
              </template>
            </v-edit-dialog>
          </template>
        </template>
        <template v-slot:item.accreditation_number="{ item }">
          <template v-for="data in item.relation">
            <v-edit-dialog
              :return-value.sync="data.accreditation_number"
              @save="updateRelation(data.id,'accreditation_number',data.accreditation_number)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ data.accreditation_number ? data.accreditation_number : '-' }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field
                  v-model="data.accreditation_number" 
                ></v-text-field>
                <!-- @input="checkAccreditationNumber(data,item.customer)"
                :error="accreditation_number_error"
                :error-messages="accreditation_number_error_message" -->
              </template>
            </v-edit-dialog>
          </template>
        </template>
        <template v-slot:item.china_apply_custom_number="{ item }">
          <template v-for="data in item.relation">
            <v-edit-dialog
              :return-value.sync="data.china_apply_custom_number"
              @save="updateRelation(data.id,'china_apply_custom_number',data.china_apply_custom_number)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ data.china_apply_custom_number ? data.china_apply_custom_number : '-' }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field
                  v-model="data.china_apply_custom_number" 
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </template>
        <template v-slot:item.commodity_total_amount="{ item }">
          <template v-for="data in item.relation">
            <div>{{ data.commodity_amount }}</div>
          </template>
        </template>
        <template v-slot:item.image="{ item }">
          <template v-for="data in item.relation">
            <v-img
              :src="get_image(data)"
              max-width="100"
              @click="displayCommodityImage(item)"
            ></v-img>
          </template>
        </template>
        <template v-slot:item.total_amount="{ item }">
          {{ get_total_amount(item) }}
        </template>
        <template v-slot:item.net_weight="{ item }">
          {{ fixedFloat(item.net_weight,2,0) }}
        </template>
        <template v-slot:item.total_net_weight="{ item }">
          {{ fixedFloat(get_total_net_weight(item),2,0) }}
        </template>
        <template v-slot:item.gross_weight="{ item }">
          {{ fixedFloat(item.gross_weight,2,0) }}
        </template>
        <template v-slot:item.total_gross_weight="{ item }">
          {{ fixedFloat(get_total_gross_weight(item),2,0) }}
        </template>
        <template v-slot:item.custom_deliver_fee="{ item }">
          <template v-if="item.custom_deliver_fee_package===false">
            <v-edit-dialog
              @save="updateField(item.id,'custom_deliver_fee',item.custom_deliver_fee)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              <div :class="item.custom_deliver_fee_status==2?'marked':''">{{ item.custom_deliver_fee }}</div><v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field v-model="item.custom_deliver_fee" @focus="$event.target.select()"></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template v-slot:item.total_custom_deliver_fee="{ item }">
          {{ get_total_custom_deliver_fee(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div style="display: flex;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-model="item.custom_deliver_fee_package"
                  :label="$t('PKG')"
                  @change="setPackageDeliverFee(item)"
                >
                </v-checkbox>
              </template>
            </v-tooltip>
            <template v-if="item.custom_deliver_fee_package===true">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-2"
                    v-on="on"
                  >mdi-information</v-icon>
                </template>
                <span>{{ getPackageDeliverFee(item) }}</span>
              </v-tooltip>
            </template>
          </div>
        </template>
        <template v-slot:item.unit_price="{ item }">
          <!-- <template v-for="data in item.relation">
            <v-edit-dialog
              @save="updateCustomsTypeUnitPrice(data.commodity.customs_type.id,'unit_price',data.commodity.customs_type.unit_price)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ data.commodity.customs_type.unit_price }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field v-model="data.commodity.customs_type.unit_price" @focus="$event.target.select()"></v-text-field>
              </template>
            </v-edit-dialog>
          </template> -->
          <template>
            <v-edit-dialog
              @save="updateField(item.id,'unit_price',item.unit_price)"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
            >
              {{ item.unit_price }} <v-icon>mdi-pencil</v-icon>
              <template v-slot:input>
                <v-text-field v-model="item.unit_price" @focus="$event.target.select()"></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </template>
        <template v-slot:item.estimate_price="{ item }">
          {{ fixedFloat(get_estimate_price(item),2,0) }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
  <script>
import BaseTable from "./BaseTable.vue";
import CustomDeliverFeeDialog from "../dialogs/CustomDeliverFeeDialog";
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import VueEasyLightbox from 'vue-easy-lightbox'
import { action_items } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/container/container_box/",
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("container.code"),
        value: "code",
        width: "3.5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.box_str"),
        value: "start_box",
        width: "3.5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.number_str"),
        value: "end_box",
        width: "3.5%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type__order",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "accreditation_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "china_apply_custom_number",
      },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.image"),
        value: "image",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_amount"),
        value: "total_amount",
        width: "1%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.commodity_per_box"),
        value: "commodity_total_amount",
        width: "1%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
        width: "3.5%",
      },
      
      {
        sortable: true,
        text: vm.$i18n.t("container.net_weight"),
        value: "net_weight",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_net_weight"),
        value: "total_net_weight",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.gross_weight"),
        value: "gross_weight",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_gross_weight"),
        value: "total_gross_weight",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.custom-deliver-fee"),
        value: "custom_deliver_fee",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.total-custom-deliver-fee"),
        value: "total_custom_deliver_fee",
      },
      // {
      //   sortable: true,
      //   text: vm.$i18n.t("container-box.apply-custom-price"),
      //   value: "apply_custom_price",
      // },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.package-custom-deliver-fee"),
        value: "actions",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs_type_unit_price"),
        value: "unit_price",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.estimate_price"),
        value: "estimate_price",
      },
    ],
    action_items: action_items,
    container: null,
    container_name: null,
    mode: 1,
    error_items: [],
    getErrorParams: {},
    total_amount: 0,
    total_net_weight: 0,
    total_custom_deliver_fee: 0,
    customs_type: null,
    customs_type_cache: null,
    lightboxVisible: false,
    images: null,
  }),
  mixins: [BaseTable],
  components: {
    CustomDeliverFeeDialog,
    CommonAlertDialog,
    CommonAutocomplete,
    VueEasyLightbox
  },
  methods: {
    get_end_box(item) {
      return item.box_amount > 1 ? item.end_box : "";
    },
    get_customs_type_name(item) {
      return item.customs_type
        ? this.getHanText(item.customs_type, "name")
        : "";
    },
    get_image(item) {
      return item.commodity.image ? item.commodity.image.file : "";
    },
    get_total_amount(item) {
      let sum = 0
      item.relation.forEach(element => {
        sum += element.commodity_amount;
      });
      return sum * item.box_amount;
    },
    get_total_net_weight(item) {
      return item.net_weight * item.box_amount;
    },
    get_total_gross_weight(item) {
      return item.gross_weight * item.box_amount;
    },
    get_total_custom_deliver_fee(item) {
      return item.custom_deliver_fee_package==true?item.custom_deliver_fee:item.custom_deliver_fee * this.get_total_amount(item); 
    },
    setPackageDeliverFee(item) {
      if (item.custom_deliver_fee_package === true) {
        this.dialogItem = this._.cloneDeep(item);
        this.action = action_items.edit;
        this.showDialog = true;
      } else {
        this.updateField(item.id, "custom_deliver_fee_package", false);
      }
    },
    onCloseEvent() {
      this.showDialog = false;
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.getApi();
    },
    updatePackageDeliverFee(item) {
      this.showDialog = false;
      let updateField = {
        custom_deliver_fee_package: item.custom_deliver_fee_package,
        custom_deliver_fee: item.custom_deliver_fee,
        custom_deliver_fee_extra: item.custom_deliver_fee_extra,
      };
      this.patchApi(item.id, updateField);
    },
    getPackageDeliverFee(item) {
      return "整包運費：" + item.custom_deliver_fee;
    },
    exportApplyList() {
      let objectDate = new Date();
      let file_name =
        objectDate.getFullYear() +
        "年" +
        (objectDate.getMonth() + 1) +
        "月" +
        objectDate.getDate() +
        "日_" +
        this.container_name +
        "_報關用";
      const url = "/container/container/" + this.container + "/getApplyList/";
      this.getFileApi(url, file_name);
    },
    setErrorResult(data) {
      this.error_items = data.results;
    },
    setResult(data){
      this.error_items = []
      this.items = []
      this.totalItems = data.count;
      // this.items = data.results;
      let current_type = null
      let current_class = 'style-1'
      this.total_amount = 0
      this.total_net_weight = 0
      this.total_custom_deliver_fee = 0
      data.results.forEach(result=>{
        if (result.customs_type.id===current_type) {
        } else {
          current_class = current_class == 'style-1'? 'style-2':'style-1'
          current_type = result.customs_type.id
        }
        result.class = current_class
        this.items.push(result)
        this.total_amount += this.get_total_amount(result)
        this.total_net_weight += this.get_total_net_weight(result)
        this.total_custom_deliver_fee += this.get_total_custom_deliver_fee(result)
      })
    },
    itemRowBackground (item) {
      return item.class
    },
    updateCustomsTypeUnitPrice(id, field, value){
      console.log(field + ": " + value);
      var jsonData = {};
      jsonData[field] = value;
      this.patchApi(id,jsonData,"/commodity/customs-type/")
    },
    get_estimate_price(item){
      return (this.get_total_net_weight(item) / this.get_total_amount(item)) * item.unit_price
    },
    updateRelation(id, field, value){
      console.log(field + ": " + value);
      var jsonData = {};
      jsonData[field] = value;
      this.patchApi(id,jsonData,"/container/container_box_relation/")
    },
    editOpen(item){
      console.log('editOpen',item)
      if (item) {
        this.customs_type = item.id;
        this.customs_type_cache = item;
      }
    },
    editClose() {
      console.log("editClose");
      this.customs_type = null;
      this.customs_type_cache = null;
    },
    displayCommodityImage(item) {
      var extra = JSON.parse(item.relation[0].commodity.extra)
      this.getImages(extra['images'])
    },
    getImages(images){
      var params = {'id__in':images}
      this.getApi("/document/document/",this.setImages,false,params)
    },
    setImages(data){
      this.images = []
      data.results.forEach(img=>{
        this.images.push({
          title: img.name,
          src: img.file
        })
      })
      this.lightboxVisible = true;
    },
    closeLightBox() {
      this.lightboxVisible = false;
    },
    refreshUnitPrice(){
      const url = "/container/container/" + this.container + "/refreshUnitPrice/";
      this.postApi({},url,this.afterRefreshUnitPrice)
    },
    afterRefreshUnitPrice(action, responseData) {
      this.getApi()
    }
  },
  mounted() {
    this.container = this.$route.query.container;
    this.mode = this.$route.query.mode;
    this.getParams = {
      container: this.container,
    };
    this.container_name = this.$route.query.name;

    this.options = {
      sortBy: ["customs_type__order"],
      sortDesc: [true],
      itemsPerPage: 200,
    };
    this.footerProps.itemsPerPageOptions = [20, 50, 100, 200]
  },
};
</script>
<style>
.style-1 {
  /* background-color: rgb(215,215,44) */
}
.style-2 {
  background-color: rgb(212, 252, 210)
}
.style-1 .text-start, .style-2 .text-start{
  text-align-last: right;
  font-size: 1.2rem !important
}
.style-1 .text-start:nth-child(1),
.style-1 .text-start:nth-child(2),
.style-1 .text-start:nth-child(3),
.style-2 .text-start:nth-child(1),
.style-2 .text-start:nth-child(2),
.style-2 .text-start:nth-child(3){
  text-align-last: center;
}

.style-1 .text-start div, .style-2 .text-start div{
  font-size: 1.2rem
}
div.v-data-table.custom-deliver-fee-detail-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > thead > tr > th > span{
  font-size: 1.2rem
}
div.v-data-table.custom-deliver-fee-detail-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(4) {
  border-left: thin solid rgba(0, 0, 0, 0.5);
  border-right: thin solid rgba(0,0,0,0.5);
  text-align-last: center;
}
div.v-data-table.custom-deliver-fee-detail-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(5) {
  text-align-last: center;
}
div.v-data-table.custom-deliver-fee-detail-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(6) {
  text-align-last: center;
}
div.v-data-table.custom-deliver-fee-detail-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(8) {
  border-left: thin solid rgba(0, 0, 0, 0.5);
  border-right: thin solid rgba(0,0,0,0.5);
}
div.v-data-table.custom-deliver-fee-detail-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(12) {
  border-left: thin solid rgba(0, 0, 0, 0.5);
  border-right: thin solid rgba(0,0,0,0.5);
}
div.v-data-table.custom-deliver-fee-detail-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(18) {
  border-left: thin solid rgba(0, 0, 0, 0.5);
  border-right: thin solid rgba(0,0,0,0.5);
}
.marked{
  background-color: rgba(32, 106, 20, 0.5)
}
</style>
