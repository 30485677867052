<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="1">
              <v-checkbox
                v-model="item.custom_deliver_fee_package"
                :label="$t('PKG')"
                :readonly="true"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.custom_deliver_fee"
                :label="$t('commodity.package-custom-deliver-fee')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="remark"
                :label="$t('commodity.remark')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  data: (vm) => ({
    topic: vm.$i18n.t("commodity.commodity"),
    remark: null
  }),
  mixins: [BaseDialog],
  methods: {
    save(){
      let editedItem = this._.cloneDeep(this.item);
      if (this.remark) {
        editedItem.custom_deliver_fee_extra = JSON.stringify({'remark':this.remark})
      }
      this.remark = null
      this.$emit("onSave", editedItem);
    },
  },
  watch: {
    item(val){
      if (val.custom_deliver_fee_extra) {
        this.remark = JSON.parse(val.custom_deliver_fee_extra)['remark']
      }
    }
  },
};
</script>