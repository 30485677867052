var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('CustomDeliverFeeDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.updatePackageDeliverFee}}),_c('vue-easy-lightbox',{attrs:{"visible":_vm.lightboxVisible,"imgs":_vm.images},on:{"hide":_vm.closeLightBox}}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
            name: 'CustomDeliverFeeList',
            params: {}
          })}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1)],1),_c('v-col',[_c('v-card',{staticClass:"px-4"},[_c('v-row',[_c('v-col',[_vm._v(_vm._s(_vm.$t('container.total_amount') + ":" + _vm.fixedFloat(_vm.total_amount,2,0)))]),_c('v-col',[_vm._v(_vm._s(_vm.$t('container.total_net_weight') + ":" + _vm.fixedFloat(_vm.total_net_weight,2,0)))]),_c('v-col',[_vm._v(_vm._s(_vm.$t('container.summary') + ":" + _vm.fixedFloat(_vm.total_custom_deliver_fee,2,0)))])],1)],1)],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.refreshUnitPrice}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("container-box.refresh-unit-price"))+" ")],1),_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.exportApplyList}},[_c('v-icon',[_vm._v("mdi-file-excel")]),_vm._v(" "+_vm._s(_vm.$t("export")+_vm.$t("apply_list"))+" ")],1)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container.custom-deliver-fee') + '--' + _vm.container_name}},[_c('v-data-table',{staticClass:"custom-deliver-fee-detail-table",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":"","fixed-header":"","height":_vm.windowHeight*0.8,"item-class":_vm.itemRowBackground},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
          var pagination = ref.pagination;
          var options = ref.options;
          var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100, 200]},on:{"update:options":updateOptions}})]}},{key:"item.end_box",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_end_box(item))+" ")]}},{key:"item.customs_type__order",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('v-edit-dialog',{attrs:{"save-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"large":""},on:{"save":function($event){return _vm.updateRelation(data.id,'customs_type',_vm.customs_type)},"open":function($event){return _vm.editOpen(data.customs_type)},"close":function($event){return _vm.editClose()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('CommonAutocomplete',{attrs:{"url":"/commodity/customs-type/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"cache":[_vm.customs_type_cache],"extra_condition":{
                  enable: true
                }},model:{value:(_vm.customs_type),callback:function ($$v) {_vm.customs_type=$$v},expression:"customs_type"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.get_customs_type_name(data))),_c('v-icon',[_vm._v("mdi-pencil")])],1)]})]}},{key:"item.accreditation_number",fn:function(ref){
                var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('v-edit-dialog',{attrs:{"return-value":data.accreditation_number,"save-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"large":""},on:{"update:returnValue":function($event){return _vm.$set(data, "accreditation_number", $event)},"update:return-value":function($event){return _vm.$set(data, "accreditation_number", $event)},"save":function($event){return _vm.updateRelation(data.id,'accreditation_number',data.accreditation_number)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{model:{value:(data.accreditation_number),callback:function ($$v) {_vm.$set(data, "accreditation_number", $$v)},expression:"data.accreditation_number"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(data.accreditation_number ? data.accreditation_number : '-')+" "),_c('v-icon',[_vm._v("mdi-pencil")])],1)]})]}},{key:"item.china_apply_custom_number",fn:function(ref){
                var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('v-edit-dialog',{attrs:{"return-value":data.china_apply_custom_number,"save-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"large":""},on:{"update:returnValue":function($event){return _vm.$set(data, "china_apply_custom_number", $event)},"update:return-value":function($event){return _vm.$set(data, "china_apply_custom_number", $event)},"save":function($event){return _vm.updateRelation(data.id,'china_apply_custom_number',data.china_apply_custom_number)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{model:{value:(data.china_apply_custom_number),callback:function ($$v) {_vm.$set(data, "china_apply_custom_number", $$v)},expression:"data.china_apply_custom_number"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(data.china_apply_custom_number ? data.china_apply_custom_number : '-')+" "),_c('v-icon',[_vm._v("mdi-pencil")])],1)]})]}},{key:"item.commodity_total_amount",fn:function(ref){
                var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity_amount))])]})]}},{key:"item.image",fn:function(ref){
                var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('v-img',{attrs:{"src":_vm.get_image(data),"max-width":"100"},on:{"click":function($event){return _vm.displayCommodityImage(item)}}})]})]}},{key:"item.total_amount",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_total_amount(item))+" ")]}},{key:"item.net_weight",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(item.net_weight,2,0))+" ")]}},{key:"item.total_net_weight",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(_vm.get_total_net_weight(item),2,0))+" ")]}},{key:"item.gross_weight",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(item.gross_weight,2,0))+" ")]}},{key:"item.total_gross_weight",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(_vm.get_total_gross_weight(item),2,0))+" ")]}},{key:"item.custom_deliver_fee",fn:function(ref){
                var item = ref.item;
return [(item.custom_deliver_fee_package===false)?[_c('v-edit-dialog',{attrs:{"save-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"large":""},on:{"save":function($event){return _vm.updateField(item.id,'custom_deliver_fee',item.custom_deliver_fee)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{on:{"focus":function($event){return $event.target.select()}},model:{value:(item.custom_deliver_fee),callback:function ($$v) {_vm.$set(item, "custom_deliver_fee", $$v)},expression:"item.custom_deliver_fee"}})]},proxy:true}],null,true)},[_c('div',{class:item.custom_deliver_fee_status==2?'marked':''},[_vm._v(_vm._s(item.custom_deliver_fee))]),_c('v-icon',[_vm._v("mdi-pencil")])],1)]:[_vm._v(" - ")]]}},{key:"item.total_custom_deliver_fee",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_total_custom_deliver_fee(item))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('PKG')},on:{"change":function($event){return _vm.setPackageDeliverFee(item)}},model:{value:(item.custom_deliver_fee_package),callback:function ($$v) {_vm.$set(item, "custom_deliver_fee_package", $$v)},expression:"item.custom_deliver_fee_package"}})]}}],null,true)}),(item.custom_deliver_fee_package===true)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getPackageDeliverFee(item)))])])]:_vm._e()],2)]}},{key:"item.unit_price",fn:function(ref){
                var item = ref.item;
return [[_c('v-edit-dialog',{attrs:{"save-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"large":""},on:{"save":function($event){return _vm.updateField(item.id,'unit_price',item.unit_price)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{on:{"focus":function($event){return $event.target.select()}},model:{value:(item.unit_price),callback:function ($$v) {_vm.$set(item, "unit_price", $$v)},expression:"item.unit_price"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.unit_price)+" "),_c('v-icon',[_vm._v("mdi-pencil")])],1)]]}},{key:"item.estimate_price",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedFloat(_vm.get_estimate_price(item),2,0))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }